import React from "react";

function WorkStatistics() {
  return (
    <section className="" style={{background: '#EBEEF2', padding: '0 0 2rem 0', marginTop: '-2rem'}}>
      <div className="container" style={{background: '#eee'}}>
        <div className="row mt-4 pt-2 position-relative" id="counter" style={{ zIndex: 1 }}>
          <div className="col-md-3 col-6 mt-4 pt-2">
            <div className="counter-box text-center">
              <img src="images/illustrator/Asset190.svg" className="avatar avatar-small" alt="" />
              <h2 className="mb-0 mt-4" style={{color: '#1F2D3D'}}>
                <span className="counter-value" data-count="100">
                  3
                </span>
                %
              </h2>
              <h6 className="counter-head text-muted">Happy Client</h6>
            </div>
          </div>

          <div className="col-md-3 col-6 mt-4 pt-2">
            <div className="counter-box text-center">
              <img src="images/illustrator/Asset189.svg" className="avatar avatar-small" alt="" />
              <h2 className="mb-0 mt-4" style={{color: '#1F2D3D'}}>
                <span className="counter-value" data-count="1">
                  1
                </span>
                +
              </h2>
              <h6 className="counter-head text-muted">Awards</h6>
            </div>
          </div>

          <div className="col-md-3 col-6 mt-4 pt-2">
            <div className="counter-box text-center">
              <img src="images/illustrator/Asset192.svg" className="avatar avatar-small" alt="" />
              <h2 className="mb-0 mt-4" style={{color: '#1F2D3D'}}>
                <span className="counter-value" data-count="2">
                  0
                </span>
              </h2>
              <h6 className="counter-head text-muted">Job Placement</h6>
            </div>
          </div>

          <div className="col-md-3 col-6 mt-4 pt-2">
            <div className="counter-box text-center">
              <img src="images/illustrator/Asset187.svg" className="avatar avatar-small" alt="" />
              <h2 className="mb-0 mt-4" style={{color: '#1F2D3D'}}>
                <span className="counter-value" data-count="99">
                  3
                </span>
                %
              </h2>
              <h6 className="counter-head text-muted">Project Complete</h6>
            </div>
          </div>
        </div>
        {/* <div className="feature-posts-placeholder bg-light" style={{background: '#eee'}}></div> */}
      </div>
    </section>
  );
}

export default WorkStatistics;
