import React, { useState } from "react"
import NavBar from "./components/NavBar/NavBar"
import HeroSection from "./components/HeroSection/HeroSection"
import WhatWeDo from "./components/WhatWeDo/WhatWeDo"
import WorkStatistics from "./components/WorkStatistics/WorkStatistics"
import WorkProcess from "./components/WorkProcess/WorkProcess"
import Footer from "./components/Footer/Footer"
import axios from "axios"
import Contact from "./components/ContactForm/ContactForm"
import ServiceDetails from "./components/ServiceDetails/ServiceDetails"
import Navbar from "./components/Navbar"
import { BrowserRouter as Router } from "react-router-dom"
import styled from 'styled-components'

const Modal = styled.div`
  background: #eee;
  position: relative;
  h5, label {
    color: #555;
  }
  input, textarea, select {
    background: #fff !important;
  }

  .blur {
    background: #ccc;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 20;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      background: #fff;
      padding: 1rem;
      h1 {
      color: #444;
      padding: 0 1rem;
    }
    p {
      color: #000;
      padding: 0 1rem;
    }
    }
   
  }
`

function App() {
  const [subject, setSubject] = useState(null)
  const [message, setMessage] = useState(null)
  const [email, setEmail] = useState(null)
  const [budget, setBudget] = useState(null)
  const [loading, isLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const getAQuote = () => {
    isLoading(true)
    const _data = {
      email: email,
      subject: subject,
      message: message,
      budget: budget,
    }
    axios
      .post("http://api.mangoconsultingllc.com/c/get-quote", _data)
      .then((response) => {
        setStatus(true)
      })
      .catch((err) => {
        alert("There was an error")
      })
    isLoading(false)
  }
  React.useEffect(() => {
    document.querySelector("#owlInit").click()
  }, [])
  return (
    <React.Fragment>
      <Router>
        {/* <NavBar /> */}
        <Navbar />
        <HeroSection />
        <WhatWeDo />
        <WorkStatistics />
        <WorkProcess />
        <Footer />

        <div
          className="modal fade"
          id="getAQuote"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document" >
            <Modal className="modal-content">
              <div className="blur">
                <div>
                  <h1>Under Maintenance</h1>
                  <p>For contact, please message us from the BOTTOM RIGHT corder widget.</p>
                </div>
               
              </div>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Get A Quote
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {!status ? (
                  <form onSubmit={() => getAQuote()}>
                    <div className="form-group">
                      <label htmlFor="email-address" className="col-form-label">
                        Email Address:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email-addreses"
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject" className="col-form-label">
                        Suject:
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setSubject(e.target.value)}
                        disabled={loading}
                      >
                        <option value="sc">Staffing / Consultants</option>
                        <option value="fsd">Full Stack Development</option>
                        <option value="mad">Mobile App Development</option>
                        <option value="ecd">E-Commerce Development</option>
                        <option value="uiux">UI/UX Design</option>
                        <option value="da">Data Analytics</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message-text" className="col-form-label">
                        Message:
                      </label>
                      <textarea
                        className="form-control"
                        id="message-text"
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={loading}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="estimated-budget" className="col-form-label">
                        Estimated Budget:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="estimated-budget"
                        onChange={(e) => setBudget(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="attachment" className="col-form-label">
                        Attachment:
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="attachment"
                        disabled={loading}
                      />
                    </div>
                  </form>
                ) : (
                  <>
                    <p>
                      Your quotation request has been submitted succesfully. Our
                      team will get back to you in couple of business days.
                      Thank you!
                    </p>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {!status &&
                  (!loading ? (
                    <button
                      type="button"
                      onClick={() => getAQuote()}
                      className="btn btn-primary"
                    >
                      Request Quotation
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary">
                      <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>{" "}
                      <span> Please Wait</span>
                    </button>
                  ))}
              </div>
            </Modal>
          </div>
        </div>

        <Contact />
      </Router>
    </React.Fragment>
  )
}

export default App
