import React from "react"
import styled from 'styled-components'

const Banner = styled.div`
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

 

  @media(max-width: 768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    @media(max-width: 600px) {
      height: 300px;
    }
  }

  .banner-content {
    h1 {
      color: #1F2D3D;
      @media(max-width: 768px) {
        font-size: 22px;
        width: 300px;
        margin: 0 auto;
        text-align: center;
       }
    }
    button {
      margin-top: 3rem;
      @media(max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
    
  }
  
`
function HeroSection() {
  return (
    <section className="bg-half-170 d-table w-100 overflow-hidden" id="home" style={{background: '#fff'}}>
      <div className="container">
        <div className="row align-items-center">

        <Banner>
            <div className="banner-content">
              <h1 className="heading mb-3"> <span className="">Grow Strong with our expertise on Small Business Tech Solution </span></h1>
              <a data-toggle="modal" data-target="#contactUs" href="#"><button className="btn btn-primary">Message Us</button></a>
            </div>
          
            <div className="classic-app-image position-relative image-container">
              <div className="bg-app-shape position-relative">
                <img src="images/app/classic01.png" className="" alt="" />
              </div>
              <div className="app-images d-none d-md-block">
                <img src="images/app/calender.png" className="" alt="" />
              </div>
            </div>
          </Banner>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;




{/* <div className="col-lg-7 col-md-7">
            <div className="title-heading">
              <h1 className="heading mb-3"> <span className="text-primary font-weight-bold">Launch your campaign and benefit from our expertise on:</span></h1>
              <h4 className="title mb-4">Web Application Development:  </h4>
              <p className="text-muted para-desc">
                Our web developers create custom web and web application solutions with delivering web presence to help you grow your business using the best web technologies. 
                Our Services include:
      
              </p>
              <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Design
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Development including admin Dashboards
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Deployment
              </li>
            </ul>
     
          <h4 className="title mb-4"> Software Development: </h4>
          <p className="text-muted para-desc">
              Not any business is the same, neither their requirements. Our proficient development team will devise the best on-demand custom solution for your business.

          </p>
          <ul className="list-unstyled text-muted">
          <h4 className="title mb-4"> Mobile Apps:</h4>
          <p className="text-muted para-desc">
              We build apps that people love. We make it easy to put your business at your customer fingertips with our intuitive and mobile app solution. 

          </p> 
         </ul>  
         
          <h4 className="title mb-4"> Business Analytics:</h4>
          <p className="text-muted para-desc">
              We provide a data-driven solution to our customers to help them to unlock powerful insights using smart analytics solutions and platforms designed to bring value.
          </p> 
          
          <p className="text-muted para-desc">
              Data-driven Solution Include:
          </p>
          
                  
          <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Data Wrangling 
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Data Analytics: Machine Learning Models
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Data Visualization
              </li>
            </ul>
                  
       <h4 className="title mb-4">Staffing/Consultations: </h4>
          <p className="text-muted para-desc">
              We provide staffing solution on a wide range of IT talents such as:
          </p> 
           <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Web Developers/QA
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Business Analyst/Project Managers
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Data Analysts/Scientists
              </li>
            </ul>
             <p className="text-muted para-desc">
              We specialize in designing and delivering premium services for organizations in the following areas: 
          </p> 
           <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Management Consulting. Assessment. Process Improvements. Project Management
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Temporary Staffing. Temp-to-Perm Staffing. Direct Hire Staffing 

              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Technology. Telecommunications. Database Mgmt. Support Services. Secure environment
              </li>
            </ul>
                     
            </div>
          </div> */}
