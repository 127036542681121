import React from "react"
import styled from "styled-components"

const ServiceWrapper = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 1.5rem;

  .title-heading {
    text-align: center;
    padding: 2rem 0;

    h1 span {
      @media (max-width: 500px) {
        font-size: 22px;
        width: 300px;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .desc-section {
      /* box-shadow: 0px 2px 38px -11px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 2px 38px -11px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 2px 38px -11px rgba(0,0,0,0.75); */
      padding: 1rem;
    }
  }

  h4 {
    color: #444;
  }

  .desc-section {
    /*width: 300px; */

    ul {
      li {
        color: #666 !important;
      }
    }

    p.para-desc {
      color: #555 !important;
    }
  }
`

const Service = styled.div`
  background: #eee;
`

const ServiceDetails = () => {
  return (
    <ServiceWrapper className="">
      <div className="title-heading">
        <h1 className="heading mb-3">
          <span className="text-primary font-weight-bold">Services</span>
        </h1>
      </div>
      <div className="container wrapper">
        <Service className="desc-section">
          <h4 className="title mb-4">Web Application Development: </h4>
          <p className="text-muted para-desc">
            We create custom websites and web application solutions with
            delivering web presence to help you grow your business using the
            best cutting edge technologies. <b />
            Our Services include:
          </p>
          <ul className="list-unstyled text-muted">
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Well Thought Strategic Design & Architect
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Employee Management System, CRM , Admin and User Portals
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Deployment/Going Live: Web Hosting & Domain Registration with AWS
            </li>
          </ul>
        </Service>

        <Service className="desc-section">
          <h4 className="title"> Automation and Added Services : </h4>
          <p className="text-muted para-desc">
            Not any business is the same, neither their requirements. Our
            proficient development team will advise the best on-demand custom
            solution for your business with Automation and Integration of
            different softwares.
          </p>

          <ul className="list-unstyled text-muted">
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Email Services with your own Domain
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Chat, Video Chat and VoIP services integerated in your website
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Notification Automation through Email or SMS
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Secure File Transfer Service Integration
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Project Management Services Integeration
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Custom Mobile Apps Solution for ios & Android
            </li>
          </ul>
        </Service>

        <Service className="desc-section">
          <h4 className="title "> Business Analytics:</h4>
          <p className="text-muted para-desc">
            We provide a data-driven solution to our customers to help them to
            unlock powerful insights using smart analytics solutions and
            platforms designed to bring value.
          </p>

          <p className="text-muted para-desc">Data-driven Solution Include:</p>

          <ul className="list-unstyled text-muted">
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Data Wrangling
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Data Analytics: Machine Learning Models
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Data Visualization
            </li>
          </ul>
        </Service>

        <Service className="desc-section">
          <h4 className="title mb-4">Staffing: </h4>
          <p className="text-muted para-desc">
            We provide staffing solution on a wide range of IT talents such as:
          </p>
          <ul className="list-unstyled text-muted">
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Web Developers/QA
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Business Analyst/Project Managers
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Data Analysts/Scientists
            </li>
          </ul>
        </Service>

        <Service className="desc-section">
          <h4 className="title mb-4">Consultations: </h4>
          <p className="text-muted para-desc">
            We specialize in designing and delivering premium services for
            organizations in the following areas:
          </p>
          <ul className="list-unstyled text-muted">
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              IT Human Resource Consulting | Assessment | Process Improvements |
              Project Management
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Temporary Staffing | Temp-to-Perm Staffing | Direct Hire Staffing
            </li>
            <li className="mb-0">
              <span className="text-primary h4 mr-2">
                <i className="uim uim-check-circle"></i>
              </span>
              Database Mgmt | Support Services | Secure environment
            </li>
          </ul>
        </Service>
      </div>
    </ServiceWrapper>
  )
}

export default ServiceDetails
