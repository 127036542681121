import React from "react";
import OurStory from "../OurStory/OurStory";
import ServiceDetails from "../ServiceDetails/ServiceDetails";
import {WHAT_WE_DO} from '../../data/index'
import styled from 'styled-components'

const ServiceCard = styled.div`
  .card {
    background: #fff !important;
    @media(max-width: 500px) {
      width: 300px;
      margin: auto;
    }
    &:hover h5 {
      color: #eee;
    }
    h5 {
      color: #1F2D3D;
    }

  }
  .para {
    color: #666 !important;
  }
`

function WhatWeDo() {
  return (
    <section className="" id="service" style={{background: '#EBEEF2'}}>
      <div className="container" style={{paddingBottom: '2rem'}}>
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4" style={{color: '#1F2D3D'}}>What we do ?</h4>
             
            </div>
          </div>
        </div>

        <div className="row">

          {/* {WHAT_WE_DO?.map(service => (
            <div className="col-lg-4 col-md-4 mt-4 pt-2 card-sec" style={{background: '#EBEEF2'}} >
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style={{background: '#EBEEF2'}}>
              <span className="h1 icon2 text-primary">
                <i className={service.icon}></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                 {service.title}
                </h5>
                <p className="para text-muted mb-0">
                 {service.desc}
                </p>
              </div>
              <span className="big-icon text-center">
                <i className={service.icon}></i>
              </span>
            </div>
          </div>
          ))} */}



        <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2 card-sec" style={{background: '#EBEEF2'}} >
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0" style={{background: '#EBEEF2'}}>
              <span className="h1 icon2 text-primary">
                <i className="uil uil-chart-line"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                  Staffing / <br /> Consultants
                </h5>
                <p className="para text-muted mb-0">
                 IT Staffing needs and professional Business consultants services.
                </p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-chart-line"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-chart-line"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                  Web Hosting <br /> Domain Registration
                </h5>
                <p className="para text-muted mb-0">
                  Web hosting and domain registration with AWS. Serverless hosting available.
                </p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-chart-line"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-crosshairs"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                 Full Stack Development <br /> Websites & Application
                </h5>
                <p className="para text-muted mb-0">
                  Employee and Client Management System design and development.
                </p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-crosshairs"></i>
              </span>
            </div>
          </ServiceCard>

         

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-rocket"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                 Mobile App <br /> Development
                </h5>
                <p className="para text-muted mb-0">Hybrid mobile app for android and ios using Flutter or React Native.</p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-rocket"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-rocket"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                  E-Commerce <br /> Development
                </h5>
                <p className="para text-muted mb-0">Top SEO maintained E-Commerce is what we are master at.</p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-rocket"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-rocket"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                  UI/UX & Logo <br /> Design
                </h5>
                <p className="para text-muted mb-0">User Interface and Web designs. Free Logo provided with Full Web Development.</p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-rocket"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-airplay"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                 Email, Chat, SMS & VoIP <br /> Workflow Automation
                </h5>
                <p className="para text-muted mb-0">Custom email with your own domain, Chat, SMS and VoIP phone number integrated websites with automatic clients follow up.</p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-airplay"></i>
              </span>
            </div>
          </ServiceCard>

          <ServiceCard className="col-lg-4 col-md-4 mt-4 pt-2">
            <div className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
              <span className="h1 icon2 text-primary">
                <i className="uil uil-rocket"></i>
              </span>
              <div className="card-body p-0 content">
                <h5>
                  Business and Data <br /> Analytics
                </h5>
                <p className="para text-muted mb-0">Marketing Analytics, Data ANalytics with tools like Python libraries or Tableu for you to analyze your data.</p>
              </div>
              <span className="big-icon text-center">
                <i className="uil uil-rocket"></i>
              </span>
            </div>
          </ServiceCard>
          {/* 
          <div className="col-lg-12 text-center col-md-4 mt-4 pt-2">
            <a href="javascript:void(0)" className="btn btn-primary">
              See more <i data-feather="arrow-right" className="fea icon-sm"></i>
            </a>
          </div> */}
        </div>
      </div>
      <ServiceDetails />

      {/* <OurStory /> */}
    </section>
  );
}

export default WhatWeDo;
