import React from "react";
function Footer() {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <a href="\#" className="logo-footer">
                <h1 className="l-light" style={{ color: "#fff", fontSize: 22 }}>
                  Mango Consulting LLC
                </h1>
              </a>
              <p className="mt-4">
                <span><b>Email:</b> contact@mangoconsultingllc.com</span><br/>
                <span><b>Phone:</b> +1 (551) 348-0707 </span><br/>
                <span>Queens, New York</span><br/>
                <span>United States</span>

              </p>
              {/* <ul className="list-unstyled social-icon social mb-0 mt-4">
                <li className="list-inline-item">
                  <a href="javascript:void(0)" className="rounded">
                    <i data-feather="facebook" className="fea icon-sm fea-social"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript:void(0)" className="rounded">
                    <i data-feather="instagram" className="fea icon-sm fea-social"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript:void(0)" className="rounded">
                    <i data-feather="twitter" className="fea icon-sm fea-social"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript:void(0)" className="rounded">
                    <i data-feather="linkedin" className="fea icon-sm fea-social"></i>
                  </a>
                </li>
              </ul> */}
            </div>

            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-light footer-head">Company</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="#about" className="text-foot">
                    <i className="mdi mdi-chevron-right mr-1"></i> About
                  </a>
                </li>
                <li>
                  <a href="#service" className="text-foot">
                    <i className="mdi mdi-chevron-right mr-1"></i> Services
                  </a>
                </li>
                <li>
                  <a href="#team" className="text-foot">
                    <i className="mdi mdi-chevron-right mr-1"></i> Team
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-light footer-head">Usefull Links</h4>
              <ul className="list-unstyled footer-list mt-4">
                <li>
                  <a href="#terms" className="text-foot">
                    <i className="mdi mdi-chevron-right mr-1"></i> Terms of Services
                  </a>
                </li>
                <li>
                  <a href="#privacy" className="text-foot">
                    <i className="mdi mdi-chevron-right mr-1"></i> Privacy Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 className="text-light footer-head">Contact Us</h4>
              <a  data-toggle="modal" data-target="#contactUs" href="#" className="btn btn-danger">
                Send a message
                </a>
            </div>
            
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
