import React from "react";

function OurStory() {
  return (
    <div className="container mt-100 mt-60" id="about">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6">
          <div className="row align-items-center">
            <div className="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div className="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                <div className="card-body p-0">
                  <img src="https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" className="img-fluid" alt="work-image" />
                  <div className="overlay-work bg-dark"></div>
                  <div className="content">
                    <a href="/#" className="title text-white d-block font-weight-bold">
                      Web Development
                    </a>
                    <small className="text-light">IT & Software</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-6">
              <div className="row">
                <div className="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <div className="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                    <div className="card-body p-0">
                      <img src="https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" className="img-fluid" alt="work-image" />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content">
                        <a href="javascript:void(0)" className="title text-white d-block font-weight-bold">
                          Michanical Engineer
                        </a>
                        <small className="text-light">Engineering</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 mt-4 pt-2">
                  <div className="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                    <div className="card-body p-0">
                      <img src="https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" className="img-fluid" alt="work-image" />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content">
                        <a href="/#" className="title text-white d-block font-weight-bold">
                          Chartered Accountant
                        </a>
                        <small className="text-light">C.A.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
          <div className="ml-lg-4">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">About Our Story</h4>
              <p className="text-muted para-desc">
                Start working with <span className="text-primary font-weight-bold">us</span> that can provide
                everything you need to generate awareness, drive traffic, connect.
              </p>
              <p className="text-muted para-desc mb-0">
                
              </p>
            </div>

            <ul className="list-unstyled text-muted">
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Fully Responsive
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Multiple Layouts
              </li>
              <li className="mb-0">
                <span className="text-primary h4 mr-2">
                  <i className="uim uim-check-circle"></i>
                </span>
                Suits Your Style
              </li>
            </ul>
            {/* 
            <div className="watch-video mt-4 pt-2">
              <a href="https://1.envato.market/4n73n" target="_blank" className="btn btn-primary mb-2">
                Read More <i data-feather="chevron-right" className="fea icon-sm"></i>
              </a>
              <a href="https://www.youtube.com/watch?v=jNTZpfXYJa4" className="video-play-icon watch text-dark mb-2 ml-2">
                <i className="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle title-dark text-white position-relative play play-iconbar"></i>{" "}
                Watch Video !
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
