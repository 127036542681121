import React, { useState, useEffect } from "react";
// import { useForm, ValidationError } from '@formspree/react';

function Contact() {
 
  // const [state, handleSubmit] = useForm("xleabveq");
  const [success, setSuccess] = useState("");
  const [status,setStatus] = useState(false)

  // useEffect(() => {
  //   if (state.succeeded) {
  //     setSuccess("Thankyou for reaching out! I will get back to you shortly.");
    
  //   }

   
  // }, [ state.succeeded]);

  useEffect(() => {
    const timer = setTimeout(() => setSuccess(''),7000);

    return () => clearTimeout(timer)
  }, [success])
  

  return (

    <div className="modal fade" id="contactUs" tabIndex="-1" role="dialog" aria-labelledby="contactUs" aria-hidden="true" >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Get A Quote</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                {!status ? (
                  // onSubmit={handleSubmit}
                <form >
                <div className="form-group">
                  <label>
                    Email <span className="red">*</span>{" "}
                  </label>
                  <input type="email" className="form-control"  required placeholder="Email Address"  name='Email'/>
                </div>

                <div className="form-group">
                  <label>
                    Subject <span className="red">*</span>{" "}
                  </label>
                  <input type="text" className="form-control" required placeholder="Your Subject"  name='Subject'/>
                </div>

                <div className="form-group">
                  <label>
                    Message <span className="red">*</span>{" "}
                  </label>
                  <textarea required placeholder="Your Messages Here"  className="form-control" name='Message'></textarea>
                </div>
                {success && <p className='success'>{success}</p>}
                <div className="download-resume">
                  <button type="submit" className="btn btn-danger form-control">
                    {/* <FaRegPaperPlane /> */}
                    <p>Send Message</p>
                  </button>
                </div>
                </form>
                       ):(
                         <>
                         <p>Your quotation request has been submitted succesfully. Our team will get back to you in couple of business days. Thank you!</p>
                         </>
                       )}
                </div>
              </div>
            </div>
          </div>
  );
}

export default Contact;