import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { RiMenu3Fill } from "react-icons/ri"

const Nav = styled.div`
  height: 60px;
  background: #eee;
  width: 100%;
  color: #555;
  position: sticky;
  top: 0;
  z-index: 50;
  box-shadow: -4px 12px 30px -22px rgba(255,255,255,1);
-webkit-box-shadow: -4px 12px 30px -22px rgba(255,255,255,0.37);
-moz-box-shadow: -4px 12px 30px -22px rgba(255,255,255,0.37);

  ._nav-container {
    max-width: 1130px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 1024px) {
      padding: 0 1rem;
    }

    ._logo {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media(max-width: 600px) {
          width: 400px;
      }

      img {
        height: 50px;
          width: 50px;

        /* @media(min-width: 600px) {
          height: 50px;
          width: 50px;
        } */
      }

      p {
        font-size: 18px !important;
        color: #666 !important;
        line-height: 18px;
        font-weight: 500;
        @media(max-width: 600px) {
          font-size: 14px;
        }
      }

      /* p {
        color: #555;
        @media(max-width: 500px) {
          font-size: 14px;
        }
      } */

     
    }

    ._nav {
      width: 70%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        width: 100%;
        height: 60px !important;
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
       

        @media (max-width: 768px) {
          display: none;
        }

        li {
          color: #555;;
        }
        button {
          border: none;
         
          padding: 6px 1rem;
          border-radius: 4px;
        }
        .normal-btn {
          background: #fff;
        }
        .active-btn {
          background: blue;
          color: #eee;
        }
      }
    }
    .mobile-menu {
      background: #eee;
      position: absolute;
      top: 60px;
      right: 0;
      z-index: 100;
      box-shadow: -13px 12px 30px -14px rgba(0,0,0,0.59);
      -webkit-box-shadow: -13px 12px 30px -14px rgba(0,0,0,0.59);
      -moz-box-shadow: -13px 12px 30px -14px rgba(0,0,0,0.59);

      @media (min-width: 768px) {
        display: none;
      }
      ul {
        list-style: none;
        padding: 2rem;

        li {
          padding: 12px 0;
          color: #444;
          letter-spacing: 1px;

          button {
          border: none;
         
          padding: 6px 1rem;
          border-radius: 4px;
          background: blue;
          color: #eee;
        }
        }
      }
    }
  }

  .hamburger {
    display: none;
    @media (max-width: 768px) {
      display: block;
      font-size: 18px;
      /* margin: 0 2rem 0 0; */
    }
  }
`;

export default function Navbar() {
  const [menu, setMenu] = useState(false);

  const [effect, setEffect] = useState(false)
  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setEffect(true)
    } else {
      setEffect(false)
    }
    // console.log(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [effect])
  return (
    <Nav>
      <div className="_nav-container">
        <div className="_logo"><img src={process.env.PUBLIC_URL + '/mango_logo.png'}/><p>Mango Consulting LLC.</p></div>
        <nav className="_nav">
          <ul>
            <a href="#"><li>Home</li></a>
            <a href="#service"><li>Services</li></a>
            <a href="#work"><li>How We Work?</li></a>
            <a href={process.env.PUBLIC_URL + '/capability.pdf'} download style={{color: '#444'}}>Capability Statement</a>
            <a data-toggle="modal" data-target="#getAQuote" href="#"><button className={!effect?'normal-btn': 'active-btn'}>Get Quote</button></a>          </ul>
        </nav>
        <p className="hamburger" onClick={() => setMenu(!menu)}><RiMenu3Fill /></p>
        <AnimatePresence exitBeforeEnter>
          {menu && (
            <motion.nav
              className="mobile-menu"
              initial={{ height: 300, width: 300, x: 300 }}
              animate={{
                x: 0,
                transition: { duration: 0.3 }
              }}
              exit={{
                x: 300,
                transition: { duration: 0.3, delay: 0.5 }
              }}
            >
              <motion.ul
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.2, staggerChildren: 0.2 }
                }}
                className=""
              >
                <a href="#">
                  <motion.li
                    initial={{ y: -20, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: { duration: 0.2, delay: 0.2 }
                    }}
                    exit={{
                      y: -20,
                      opacity: 0,
                      transition: { duration: 0.2, delay: 0.2 }
                    }}
                    onClick={() => setMenu(false)}
                    className=""
                  >
                    Home
                  </motion.li>
                </a>
                <a href="#service">
                <motion.li
                  initial={{ y: -20, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.2, delay: 0.3 }
                  }}
                  exit={{
                    y: -20,
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.3 }
                  }}
                  onClick={() => setMenu(false)}
                  className=""
                >
                  Services
                </motion.li>
                </a>
                <a href="#work">
                <motion.li
                  initial={{ y: -20, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.2, delay: 0.4 }
                  }}
                  exit={{
                    y: -20,
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.4 }
                  }}
                  onClick={() => setMenu(false)}
                  className=""
                >
                  How We Work
                </motion.li>
                </a>
                <a href="/capability_statement.pdf" download>
                <motion.li
                  initial={{ y: -20, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.2, delay: 0.5 }
                  }}
                  exit={{
                    y: -20,
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.5 }
                  }}
                  onClick={() => setMenu(false)}
                  className=""
                >
                  Capability Statement
                </motion.li>
                </a>

                <a data-toggle="modal" data-target="#getAQuote" href="#">
                <motion.li
                  initial={{ y: -20, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.2, delay: 0.6 }
                  }}
                  exit={{
                    y: -20,
                    opacity: 0,
                    transition: { duration: 0.2, delay: 0.6 }
                  }}
                  onClick={() => setMenu(false)}
                  className=""
                >
                  <button className={!effect?'normal-btn': 'active-btn'}>Get Quote</button>
                </motion.li>
                </a>
              </motion.ul>
            </motion.nav>
          )}
        </AnimatePresence>
      </div>
    </Nav>
  );
}
