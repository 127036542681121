import React, { useState, useEffect} from "react";
import { HiOutlineX } from 'react-icons/hi'

function NavBar() {
  const [effect, setEffect] = useState(false)
  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setEffect(true)
    } else {
      setEffect(false)
    }
    // console.log(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [effect])
  return (
    <header id="topnav" className="defaultscroll sticky" style={{background: '#1F2D3D'}}>
      <div className="container">
        <div>
          <a className="logo" href="#">
            {/* <img src="images/logo-dark.png" className="l-dark" height="24" alt="" /> */}
            <h1 className="l-dark" style={{ color: "#000", fontSize: 22 }}>
              Mango Consulting LLC
            </h1>
            <h1 className="l-light" style={{ color: "#fff", fontSize: 22 }}>
              Mango Consulting LLC
            </h1>
            {/* <img src="images/logo-light.png" className="l-light" height="24" alt="" /> */}
          </a>
        </div>
        <div className="buy-button">
          <a  data-toggle="modal" data-target="#getAQuote" href="#">
            <div className="btn btn-primary login-btn-primary">Get a Quote</div>
            <div className="btn btn-light login-btn-light">Get a Quote</div>
          </a>
        </div>
        <div className="menu-extras">
          <div className="menu-item">
            <a className="navbar-toggle" id="toggle">
              <div className="lines" id="toggle">
                <span id="toggle"></span>
                <span id="toggle"></span>
                <span id="toggle"></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
           <HiOutlineX className="navbar-toggle" id="toggle" style={{ position: 'absolute', bottom: '10px', right: '0px'}}/>
        
          <ul className="navigation-menu nav-light">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#service">Service</a>
            </li>
            <li>
              <a href="#work">How we work</a>
            </li>
            <li style={{ width: '200px'}}>
              <a href="/capability.pdf" download>capability_statement</a>
            </li>
          </ul>
          <div className="buy-menu-btn d-none">
            <a href="#" className="btn btn-primary">
              Get a Quote
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
