import React from "react";
import styled from 'styled-components'

const Slider = styled.div`
  background: #EBEEF2;
  padding: 1rem;

  p {
    color: #444 !important;
    margin-bottom: 7px;
  }
`

function WorkProcess() {
  return (
    <section className="" id="work" style={{padding: '2rem 0', margin: '0', background: '#fff'}}>
      <div className="container pb-md-5 mb-md-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <span className="badge badge-pill badge-success mb-2">Testimonials</span>
              <h4 className="title mb-4">What our clients says !</h4>
              {/* <p className="text-muted para-desc mx-auto mb-0">
                Start working with <span className="text-primary font-weight-bold">Landrick</span> that can provide
                everything you need to generate awareness, drive traffic, connect.
              </p> */}
            </div>
          </div>
        </div>

        <div className="row justify-content-center" >
          <div className="col-lg-12 mt-4">
            <div id="customer-testi" className="owl-carousel owl-theme" >
              <div className="media customer-testi m-2">
                <img src={process.env.PUBLIC_URL + '/Prabesh.jpeg'} className="avatar avatar-small mr-3 rounded shadow" alt="" />
                <Slider className="media-body content p-3 shadow rounded position-relative">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <p className="text-muted mt-2">
                    " It's been a great experience with Mongo Consulting LLC. Highly recommended. "
                  </p>
                  <h6 className="text-primary">
                    - Prabesh Regmi <small className="text-muted"></small>
                  </h6>
                </Slider>
              </div>

              <div className="media customer-testi m-2">
                <img src="" className="avatar avatar-small mr-3 rounded shadow" alt="" />
                <Slider className="media-body content p-3 shadow rounded position-relative">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star-half text-warning"></i>
                    </li>
                  </ul>
                  <p className="text-muted mt-2">
                    " They not only build our websites but also helped us capture future developments targeting our KPIs . I'm really happy and I would recommend Mango Consulting to everyone. Amazing Advising Work "
                  </p>
                  <h6 className="text-primary">
                    - Suyash Rayamajhi <small className="text-muted">CEO, Everest Tax & Accounting.</small>
                  </h6>
                </Slider>
              </div>

              <div className="media customer-testi m-2">
                <img src="https://images.pexels.com/photos/4318392/pexels-photo-4318392.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" className="avatar avatar-small mr-3 rounded shadow" alt="" />
                <Slider className="media-body content p-3 shadow rounded position-relative">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <p className="text-muted mt-2">
                    " I really enjoyed working with them to complete my project. Great experience with Mango Consulting LLC. They do their research about their clients nicely "
                  </p>
                  <h6 className="text-primary">
                    - Dinesh Yadav <small className="text-muted">Cybersecurity Advisor</small>
                  </h6>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkProcess;
